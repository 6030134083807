import React from 'react';
import styles from './Videos.module.css';
import { ReactComponent as IconStore } from '../../Assets/store.svg';
import { ReactComponent as IconTalk } from '../../Assets/talk.svg';
import { ReactComponent as IconPlay } from '../../Assets/play.svg';
import videoStore from '../../Assets/video-store.jpg';
import videoTalk from '../../Assets/video-talk.jpg';
import Modal from '../Modal/Modal';

const Videos = () => {

   const listVideos = [
      { id: 0, link: 'axFX3qegs1k', cover: videoStore, text: 'Conheça nossas lojas' },
      { id: 1, link: 'jmGCFmYJPhs', cover: videoTalk, text: 'Saiba a opinião delas' },
   ];
   const [currentVideo, setCurrentVideo] = React.useState(null);
   const [modalActive, setModalActive] = React.useState(false);

   function handleClick(link) {
      setCurrentVideo(link);
      setModalActive(true);
   }

   return (
      <section className={styles.videos}>
         <div className="container">

            <div className={styles.wrapper}>

               {listVideos && listVideos.map(video => (
                  <div className={styles.video} key={video.id}>
                     <button onClick={() => handleClick(video.link)}>
                        <div className={styles.name}>{video.text}</div>
                        <div className={styles.overlay}>
                           <IconPlay />
                        </div>
                        <img src={video.cover} alt="Vídeo" className="img-fluid" />
                     </button>
                  </div>
               ))}

               <Modal
                  link={currentVideo}
                  setModalActive={setModalActive}
                  modalActive={modalActive}
               />

            </div>

         </div>
      </section>
   )
}

export default Videos;