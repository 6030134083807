import React from 'react';
import styles from './Message.module.css';
import whatsapp from '../../Assets/whatsapp.svg'

const Message = () => {
   return (
      <section className={styles.message}>
         <div className={styles.cta}>
            <h3 className={styles.title}>
               <span>Gostou?</span>
               Agende agora mesmo sua avaliação!
            </h3>
            <a
               href="https://api.whatsapp.com/send?phone=5521966157908&text=Olá,%20gostaria%20de%20agendar%20uma%20avaliação!"
               target="_blank"
               rel="noreferrer noopener"
               className={styles.button}>
               <i>
                  <img src={whatsapp} alt="" className="img-fluid" />
               </i>
               Agendar avaliação
            </a>
         </div>
      </section>
   )
}

export default Message;
