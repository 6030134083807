import './App.css';
import Banner from './Components/Banner/Banner';
import Footer from './Components/Footer/Footer';
import Message from './Components/Message/Message';
import Videos from './Components/Videos/Videos';

function App() {
  return (
    <div className="App">
      <Banner />
      <Videos />
      <Message />
      <Footer />
    </div>
  );
}

export default App;
