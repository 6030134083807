import React from 'react'
import styles from './Banner.module.css'
import logo from '../../Assets/logo.png'
import banner from '../../Assets/banner.jpg'
import { ReactComponent as IconInstagram } from '../../Assets/instagram.svg'
import { ReactComponent as IconFacebook } from '../../Assets/facebook.svg'

const Banner = () => {
   return (
      <section className={styles.banner} style={{
         'backgroundImage': `url(${banner})`
      }}>
         <div className="container">

            <div className={styles.wrapper}>

               <div className={styles.content}>
                  <div className={styles.networks}>
                     <span className="only-lg">Siga nossas redes sociais</span>

                     <div className={styles.list}>
                        <a
                           href="https://www.facebook.com/patyperfecthair.alongamentodecabelosnaturais/"
                           rel="noreferrer noopener"
                           target="_blank"
                        >
                           <IconFacebook />
                        </a>
                        <a
                           href="https://instagram.com/patyperfecthair/"
                           rel="noreferrer noopener"
                           target="_blank"
                        >
                           <IconInstagram />
                        </a>
                     </div>
                  </div>

                  <div className={styles.logo}>
                     <img src={logo} alt="Logo Paty Perfect Hair" className="img-fluid" />
                  </div>

                  <div className={styles.text}>
                     <h1>Mais de 5 mil mulheres já realizaram o sonho de um cabelo simplesmente perfeito. E o seu?</h1>
                  </div>
               </div>

            </div>

         </div>
      </section >
   )
}

export default Banner
