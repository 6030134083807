import React from 'react';
import styles from './Modal.module.css';

const Modal = ({ link, setModalActive, modalActive }) => {

   function handleClickOut(e) {
      if (e.target === e.currentTarget) {
         setModalActive(false);
      }
   }

   return (
      <div className={`${styles.modal} ${modalActive && styles.active}`} onClick={handleClickOut}>
         <div className={styles.content}>
            {link !== null && (
               <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${link}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen />
            )}

         </div>
      </div>
   )
}

export default Modal;
